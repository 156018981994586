import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_QcG8fy6QEBltAURJRsLXPhduU_C9j_I",
  authDomain: "shopping-list-48367.firebaseapp.com",
  projectId: "shopping-list-48367",
  storageBucket: "shopping-list-48367.appspot.com",
  messagingSenderId: "385350781204",
  appId: "1:385350781204:web:5083e07e37d3f105cd1a01",
  measurementId: "G-V19NZSX1FE"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app)
export const analytics = getAnalytics(app);