import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap"
import { AppDispatch, RootState } from "../../Store/Store"
import { useDispatch, useSelector } from "react-redux"
import { showNotifications } from "../../Store/Notifications/Notifications.slice"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useNotificationsMarkReadMutation } from "../../Store/Notifications/Notifications.service"
import { DateService } from "../../Services/Date"

function Notifications() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const show = useSelector((state: RootState) => state.notifications.show)
    const notifications = useSelector((state: RootState) => state.notifications.notifications)

    const [markRead] = useNotificationsMarkReadMutation()

    useEffect(() => {
        if (show) {
            markRead()
        }
    }, [show, markRead])

    const handleOpen = (url?: string) => {
        if (url) {
            dispatch(showNotifications(false))
            navigate(url)
        }
    }

    return (
        <Offcanvas show={show} onHide={() => dispatch(showNotifications(false))}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Notifications</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {notifications.map(notification => {
                    let border
                    if (!notification.isRead) {
                        border="info"
                    }
                    return (
                        <Card
                        key={notification._id}
                        className="mb-3"
                        border={border}
                        >
                            <Card.Body>
                                <Card.Text className="fw-bold">{notification.title}</Card.Text>
                                <Card.Text>{notification.text}</Card.Text>

                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col className="small">{DateService.formatDate(notification.createdAt, "MMM Do YY")}</Col>
                                    {notification.url ? <Col className="text-end">
                                        <Button
                                            variant="link"
                                            size="sm"
                                            onClick={() => handleOpen(notification.url)}
                                        >
                                            Open
                                        </Button></Col> : null}
                                </Row>


                            </Card.Footer>
                        </Card>
                    )
                })}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default Notifications