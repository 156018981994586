import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap"

function Account() {

    const { user } = useAuth0();

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Account</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>Profile</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>Name</Col>
                                <Col>{user?.name}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Account