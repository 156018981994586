import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MemberState } from './Types'

const initialState: MemberState = {
    showMemberFinder: false,
}

export const memberSlice = createSlice({
    name: 'Member',
    initialState,
    reducers: {
        showMemberFinder(state, action: PayloadAction<boolean>) {
            state.showMemberFinder = action.payload
        },
    },
})

export const {
    showMemberFinder,
} = memberSlice.actions
export default memberSlice.reducer