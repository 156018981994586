import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap"
import { ReactNode, useState } from "react"

function EditTextarea({
    defaultValue,
    placeholder,
    icon,
    disabled,
    handleUpdate,
}: {
    defaultValue?: string
    placeholder?: string
    icon: ReactNode
    disabled?: boolean
    handleUpdate: (text: string) => void
}) {

    const [edit, setEdit] = useState(false)
    const [text, setText] = useState(defaultValue ?? '')

    const handleSave = (text: string) => {
        setEdit(false)
        handleUpdate(text)
    }

    let className: string | undefined = "text-secondary"
    if (defaultValue) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{defaultValue ? defaultValue : placeholder ? placeholder : ''}</Col>
    </Row>
    if (edit) {
        view = <Row>
            <Col>
                <Form.Control
                    type="text"
                    as="textarea"
                    autoFocus={edit}
                    placeholder={placeholder ?? ''}
                    defaultValue={defaultValue}
                    onChange={e => setText(e.target.value)}
                    disabled={disabled}
                />
                <ButtonGroup>
                    <Button
                        size="sm"
                        variant="link"
                        onClick={() => handleSave(text)}
                    >
                        Save
                    </Button>
                    <Button
                        size="sm"
                        variant="link"
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    }

    return view
}

export default EditTextarea