import { baseSplitApi } from "../../Services/BaseApi"
import { IStore } from "../../Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        getStoreById: build.query<IStore, string>({
            query: (storeId) => '/stores/' + storeId,
        }),
    }),
    overrideExisting: false,
})

export const {
    useLazyGetStoreByIdQuery,
} = api