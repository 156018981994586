import { Button, ListGroup, Offcanvas } from "react-bootstrap"
import { Currency, currencyCodes, currencyCodesType, IListProduct, IStore } from "../../../Types"
import EditText from "../../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import { useDeleteListProductMutation, useListStoresQuery, useUpdateListProductMutation } from "../../../Store/Event/Event.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"
import EditNumber from "../../EditResource/EditNumber"
import { CurrencyService } from "../../../Services/Currency"
import EditList from "../../EditResource/EditList"
import { useMemo } from "react"

function EditProduct({ show, handleClose, product }: { show: boolean, handleClose: () => void, product: IListProduct }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateProduct, updateProductResult] = useUpdateListProductMutation()
    const [deleteProduct, deleteProductResult] = useDeleteListProductMutation()

    const query = useListStoresQuery('')

    const currencies = useMemo(() => {
        const currencies: Currency[] = []
        for (const code of currencyCodes) {
            const currency = CurrencyService.getCurrency(code)
            if (currency) {
                currencies.push(currency)
            }
        }
        return currencies
    }, [])

    const handleUpdate = async (value: string | number, key: "title" | "description" | "unitPrice" | "weightGr" | "currency" | "storeId" | "qty") => {
        if (!product) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateProduct({ eventId: product.eventId, listId: product.listId, productId: product._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const getDisplayLabelStore = (storeId: string) => {
        if (!query.data) {
            return ''
        }
        let store: IStore | null = null
        for (const storeItem of query.data) {
            if (storeItem._id === storeId) {
                store = storeItem
                break
            }
        }
        if (store) {
            return <>
                <div>{store.name}</div>
                <div className="small">{store.address}</div>
                <div className="small">{store.description}</div>
            </>
        }
        return ''
    }

    const getDisplayItemStore = (store: IStore) => <>
        <div>{store.name}</div>
        <div className="small">{store.address}</div>
        <div className="small">{store.description}</div>
    </>

    const getDisplayLabelCurrency = (code: currencyCodesType) => {
        const currency = CurrencyService.getCurrency(code)
        if (currency) {
            return <>
                {currency.name} {currency.symbol}
            </>
        }
        return ''
    }

    const getDisplayItemCurrency = (currency: Currency) => {
        if (!currency) {
            return null
        }
        return (
            <>
                <div>{currency.symbol}</div>
                <div className="small">{currency.name}</div>
            </>
        )
    }

    const onFilterCurrency = (search: string, objects: Currency[]) => {
        return objects.filter(currency => {
            if (currency.code.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (currency.name && currency.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
        })
    }

    const onFilterStore = (search: string, objects: IStore[]) => {
        return objects.filter(store => {
            if (store.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.description && store.description.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.address && store.address.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
        })
    }

    const onDelete = async () => {
        const confirm = window.confirm(`Delete product ${product.title}?`)
        if (confirm) {
            if (!product) {
                return
            }
            try {
                await deleteProduct({
                    eventId: product.eventId,
                    listId: product.listId,
                    productId: product._id,
                }).unwrap()
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const currencyIcon = useMemo(() => {
        let icon = <Icon.Icon123 />
        const symbol = CurrencyService.getCurrency(product.currency as currencyCodesType)?.symbol
        if (symbol) {
            icon = <>{symbol}</>
        }
        return icon
    }, [product.currency])

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Product</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup>
                    <ListGroup.Item>
                        <EditText
                            icon={<Icon.Fonts />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.title}
                            placeholder="Product name"
                            handleUpdate={text => handleUpdate(text, "title")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditNumber
                            icon={<Icon.Icon123 />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.qty}
                            placeholder="Add quantity"
                            handleUpdate={text => handleUpdate(text, "qty")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditTextarea
                            icon={<Icon.CardText />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.description}
                            placeholder="Add description"
                            handleUpdate={text => handleUpdate(text, "description")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditList<Currency>
                            icon={<Icon.Cash />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.currency}
                            placeholder="Add currency"
                            handleUpdate={code => handleUpdate(code, "currency")}
                            objects={currencies}
                            primKey="code"
                            title="Select currency"
                            isMandatory={false}
                            getDisplayLabel={code => getDisplayLabelCurrency(code as currencyCodesType)}
                            getDisplayItem={getDisplayItemCurrency}
                            onFilter={onFilterCurrency}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditNumber
                            icon={currencyIcon}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.unitPrice}
                            placeholder="Add unit price"
                            handleUpdate={num => handleUpdate(num, "unitPrice")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditNumber
                            icon={<Icon.Stack />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.weightGr}
                            placeholder="Add weight"
                            handleUpdate={num => handleUpdate(num, "weightGr")}
                            right={<>gr</>}
                        />
                    </ListGroup.Item>
                    {query.data ? <ListGroup.Item>
                        <EditList<IStore>
                            icon={<Icon.Shop />}
                            disabled={updateProductResult.isLoading}
                            defaultValue={product.storeId}
                            placeholder="Add store"
                            handleUpdate={storeId => handleUpdate(storeId, "storeId")}
                            objects={query.data}
                            primKey="_id"
                            title="Select store"
                            isMandatory={false}
                            getDisplayLabel={getDisplayLabelStore}
                            getDisplayItem={getDisplayItemStore}
                            onFilter={onFilterStore}
                        />
                    </ListGroup.Item> : null}
                </ListGroup>

                <div className="d-grid gap-2 mt-3">
                    <Button
                        type='submit'
                        variant="danger"
                        disabled={deleteProductResult.isLoading}
                        onClick={onDelete}
                    ><Icon.Trash /> Delete</Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default EditProduct