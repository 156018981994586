import { configureStore } from '@reduxjs/toolkit'
import { baseSplitApi } from '../Services/BaseApi'
import toastReducer from '../Store/Toast/Toast.slice'
import productReducer from '../Store/Product/Product.slice'
import storeReducer from '../Store/Store/Store.slice'
import userConfigReducer from '../Store/UserConfig/UserConfig.slice'
import memberReducer from '../Store/Member/Member.slice'
import notificationsReducer from '../Store/Notifications/Notifications.slice'

export const store = configureStore({
    reducer: {
        toast: toastReducer,
        product: productReducer,
        store: storeReducer,
        userConfig: userConfigReducer,
        member: memberReducer,
        notifications: notificationsReducer,
        [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat(baseSplitApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch