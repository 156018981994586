import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Col, Container, ListGroup, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetSharedEventQuery, useListSharedShoppingListsQuery } from "../../Store/Event/Event.service"
import EventUserAvatars from "../EventUserAvatars"
import ShoppingListItem from "../ShoppingListItem"
import { useMemo } from "react"
import { Member } from "../../Types"

function SharedEvent() {

    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ?? skipToken
    const eventQuery = useGetSharedEventQuery(args)

    const listsQuery = useListSharedShoppingListsQuery(args)

    const members = useMemo(() => {
        if (eventQuery.isLoading || !eventQuery.isSuccess || !eventQuery.data?.members?.length) {
            return []
        }
        const list = eventQuery.data
        const members = list.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [eventQuery.isLoading, eventQuery.isSuccess, eventQuery.data])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/shared-events')}>Shared Events</Breadcrumb.Item>
                        <Breadcrumb.Item active>{eventQuery.data?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{eventQuery.data?.title}</h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {members.length ? <EventUserAvatars users={members} /> : null}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>Shopping Lists</h3>
                </Col>
            </Row>
            {eventId ? <Row>
                <Col>
                    <ListGroup>
                        {listsQuery.data?.map(list => <ShoppingListItem
                            key={list._id}
                            eventId={eventId}
                            list={list}
                            isShared={true}
                            canEdit={false}
                        />)}
                    </ListGroup>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default SharedEvent