import { baseSplitApi } from "../../Services/BaseApi"
import { IEvent, IEventInvitation, IListProduct, InvitationUsers, IProduct, IShoppingList, IShoppingListInvitation, IStore, IUser, SharedListsEvents } from "../../Types"
import { AddEventInvitationPayload, AddEventPayload, AddListProductPayload, AddShoppingListInvitationPayload, AddShoppingListPayload, UpdateEventPayload, UpdateListProductPayload, UpdateProductPayload, UpdateShoppingListPayload, UpdateStorePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listEvents: build.query<IEvent[], void>({
            query: () => '/events',
            providesTags: ["Events"]
        }),
        getEvent: build.query<IEvent, string>({
            query: (eventId) => '/events/' + eventId,
            providesTags: ["Events"]
        }),
        addEvent: build.mutation<IEvent, AddEventPayload>({
            query: (payload) => ({
                url: '/events',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Events"]
        }),
        updateEvent: build.mutation<void, UpdateEventPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Events"]
        }),
        deleteEvent: build.mutation<void, { eventId: string }>({
            query: ({ eventId }) => ({
                url: '/events/' + eventId,
                method: 'DELETE',
            }),
            invalidatesTags: ["Events"]
        }),
        listShoppingLists: build.query<IShoppingList[], string>({
            query: (eventId) => '/events/' + eventId + '/lists',
            providesTags: ["ShoppingLists"]
        }),
        getShoppingList: build.query<IShoppingList, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/events/' + eventId + '/lists/' + listId,
            providesTags: ["ShoppingLists"]
        }),
        addShoppingList: build.mutation<IShoppingList, AddShoppingListPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        updateShoppingList: build.mutation<void, UpdateShoppingListPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists/' + payload.listId,
                method: 'PUT',
                body: {
                    title: payload.title,
                    description: payload.description,
                    assigneeUserId: payload.assigneeUserId,
                    scheduleAt: payload.scheduleAt,
                },
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        deleteShoppingList: build.mutation<void, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => ({
                url: '/events/' + eventId + '/lists/' + listId,
                method: 'DELETE',
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        listListProducts: build.query<IListProduct[], { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/events/' + eventId + '/lists/' + listId + '/list-products',
            providesTags: ["ListProducts"]
        }),
        getListProduct: build.query<IListProduct, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId,
        }),
        addListProduct: build.mutation<IListProduct, AddListProductPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists/' + payload.listId + '/list-products',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ListProducts"]
        }),
        deleteListProduct: build.mutation<void, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["ListProducts"]
        }),
        updateListProduct: build.mutation<void, UpdateListProductPayload>({
            query: (body) => ({
                url: '/events/' + body.eventId + '/lists/' + body.listId + '/list-products/' + body.productId,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListProducts"]
        }),
        markListProductDone: build.mutation<void, { eventId: string, listId: string, ids: string[], isDone: boolean }>({
            query: ({ eventId, listId, ids, isDone }) => ({
                url: '/events/' + eventId + '/lists/' + listId + `/list-products${isDone ? '?isDone=1' : ''}`,
                method: 'PUT',
                body: ids,
            }),
            invalidatesTags: ["ListProducts"]
        }),
        listProducts: build.query<IProduct[], string>({
            query: (searchQuery) => '/products?q=' + searchQuery,
            providesTags: ["Products"]
        }),
        updateProduct: build.mutation<void, UpdateProductPayload>({
            query: (payload) => ({
                url: '/products/' + payload.productId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProducts: build.mutation<void, string[]>({
            query: (body) => ({
                url: '/products',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProduct: build.mutation<void, string>({
            query: (productId) => ({
                url: '/products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["Products"]
        }),
        listStores: build.query<IStore[], string>({
            query: (searchQuery) => '/stores?q=' + searchQuery,
            providesTags: ["Stores"]
        }),
        getStore: build.query<IStore, string>({
            query: (storeId) => '/stores/' + storeId,
            providesTags: ["Stores"]
        }),
        updateStore: build.mutation<void, UpdateStorePayload>({
            query: (payload) => ({
                url: '/stores/' + payload.storeId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Stores"]
        }),
        deleteStores: build.mutation<void, string[]>({
            query: (body) => ({
                url: '/stores',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["EventInvitations", "InvitationUsers"]
        }),
        deleteStore: build.mutation<void, string>({
            query: (storeId) => ({
                url: '/stores/' + storeId,
                method: 'DELETE',
            }),
            invalidatesTags: ["EventInvitations", "InvitationUsers"]
        }),
        addEventInvitation: build.mutation<IEventInvitation, AddEventInvitationPayload>({
            query: (payload) => ({
                url: '/event-invitations',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Events", "InvitationUsers"]
        }),
        deleteEventInvitation: build.mutation<IEventInvitation, { eventId: string, email: string }>({
            query: (body) => ({
                url: '/event-invitations',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["Events", "InvitationUsers"]
        }),
        validateEventInvitation: build.query<{ eventId: string, title: string, status: "PENDING" | "ACCEPTED", inviterName: string }, string>({
            query: (token) => '/event-invitations-validation/' + token,
        }),
        acceptEventInvitation: build.mutation<void, string>({
            query: (token) => '/event-invitations-accept/' + token,
            invalidatesTags: ["EventInvitations", "InvitationUsers"]
        }),
        listShoppingListInvitations: build.query<IShoppingListInvitation[], string>({
            query: (eventId) => '/event-invitations/events/' + eventId,
            providesTags: ["ShoppingListInvitations"]
        }),
        addShoppingListInvitation: build.mutation<IShoppingListInvitation, AddShoppingListInvitationPayload>({
            query: (payload) => ({
                url: '/shopping-list-invitations',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ShoppingLists", "InvitationUsers"]
        }),
        deleteShoppingListInvitation: build.mutation<IShoppingListInvitation, { listId: string, email: string }>({
            query: (body) => ({
                url: '/shopping-list-invitations',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["ShoppingLists", "InvitationUsers"]
        }),
        validateShoppingListInvitation: build.query<{ eventId: string, listId: string, title: string, status: "PENDING" | "ACCEPTED", inviterName: string }, string>({
            query: (token) => '/shopping-list-invitations-validation/' + token,
        }),
        acceptShoppingListInvitation: build.mutation<void, string>({
            query: (token) => '/shopping-list-invitations-accept/' + token,
            invalidatesTags: ["ShoppingListInvitations", "InvitationUsers"]
        }),
        listSharedEvents: build.query<(IEvent & { lists: IShoppingList[] })[], void>({
            query: () => '/shared-events',
            providesTags: ["SharedEvents"]
        }),
        getSharedEvent: build.query<IEvent, string>({
            query: (eventId) => '/shared-events/' + eventId,
            providesTags: ["SharedEvents"]
        }),
        listSharedShoppingLists: build.query<IShoppingList[], string>({
            query: (eventId) => '/shared-events/' + eventId + '/shared-lists',
            providesTags: ["SharedShoppingLists"]
        }),
        getSharedShoppingList: build.query<IShoppingList, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/shared-events/' + eventId + '/shared-lists/' + listId,
            providesTags: ["SharedShoppingLists"]
        }),
        listSharedListProducts: build.query<IListProduct[], { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/shared-events/' + eventId + '/shared-lists/' + listId + '/shared-list-products',
            providesTags: ["SharedListProducts"]
        }),
        addSharedListProduct: build.mutation<IListProduct, AddListProductPayload>({
            query: (payload) => ({
                url: '/shared-events/' + payload.eventId + '/shared-lists/' + payload.listId + '/shared-list-products',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        deleteSharedListProduct: build.mutation<void, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => ({
                url: '/shared-events/' + eventId + '/shared-lists/' + listId + '/shared-list-products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        updateSharedListProduct: build.mutation<void, UpdateListProductPayload>({
            query: (body) => ({
                url: '/shared-events/' + body.eventId + '/shared-lists/' + body.listId + '/shared-list-products/' + body.productId,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        markSharedListProductDone: build.mutation<void, { eventId: string, listId: string, ids: string[], isDone: boolean }>({
            query: ({ eventId, listId, ids, isDone }) => ({
                url: '/shared-events/' + eventId + '/shared-lists/' + listId + `/shared-list-products${isDone ? '?isDone=1' : ''}`,
                method: 'PUT',
                body: ids,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        listUsers: build.query<InvitationUsers, string>({
            query: (listId) => '/list-users/' + listId,
            providesTags: ["InvitationUsers"]
        }),
        listSharedEventLists: build.query<SharedListsEvents, void>({
            query: () => '/shared-event-lists',
        }),
        listInvitedUsers: build.query<IUser[], void>({
            query: () => '/invited-users',
        }),
    }),
    overrideExisting: false,
})

export const {
    useListEventsQuery,
    useGetEventQuery,
    useAddEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useListShoppingListsQuery,
    useGetShoppingListQuery,
    useAddShoppingListMutation,
    useUpdateShoppingListMutation,
    useDeleteShoppingListMutation,
    useListListProductsQuery,
    useGetListProductQuery,
    useAddListProductMutation,
    useDeleteListProductMutation,
    useUpdateListProductMutation,
    useMarkListProductDoneMutation,
    useListProductsQuery,
    useUpdateProductMutation,
    useDeleteProductsMutation,
    useDeleteProductMutation,
    useListStoresQuery,
    useGetStoreQuery,
    useUpdateStoreMutation,
    useDeleteStoresMutation,
    useDeleteStoreMutation,
    useAddEventInvitationMutation,
    useDeleteEventInvitationMutation,
    useValidateEventInvitationQuery,
    useAcceptEventInvitationMutation,
    useListShoppingListInvitationsQuery,
    useAddShoppingListInvitationMutation,
    useDeleteShoppingListInvitationMutation,
    useValidateShoppingListInvitationQuery,
    useAcceptShoppingListInvitationMutation,
    useListSharedEventsQuery,
    useGetSharedEventQuery,
    useListSharedShoppingListsQuery,
    useGetSharedShoppingListQuery,
    useListSharedListProductsQuery,
    useAddSharedListProductMutation,
    useUpdateSharedListProductMutation,
    useDeleteSharedListProductMutation,
    useMarkSharedListProductDoneMutation,
    useListSharedEventListsQuery,
    useListInvitedUsersQuery,
} = api