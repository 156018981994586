import { Col, Form, InputGroup, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { ReactNode, useState } from "react"

function EditText({
    defaultValue,
    placeholder,
    icon,
    disabled,
    handleUpdate,
}: {
    defaultValue?: string
    placeholder?: string
    icon: ReactNode
    disabled?: boolean
    handleUpdate: (text: string) => void
}) {

    const [edit, setEdit] = useState(false)
    const [text, setText] = useState(defaultValue ?? '')

    const handleSave = (text: string) => {
        setEdit(false)
        handleUpdate(text)
    }

    let className: string | undefined = "text-secondary"
    if (defaultValue) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{defaultValue ? defaultValue : placeholder ? placeholder : ''}</Col>
    </Row>
    if (edit) {
        view = <Row>
            <Col xs='auto'>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={placeholder ?? ''}
                        defaultValue={defaultValue}
                        onChange={e => setText(e.target.value)}
                        disabled={disabled}
                    />
                    <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => handleSave(text)}
                    >
                        <Icon.Check />
                    </InputGroup.Text>
                    <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => setEdit(false)}
                    >
                        <Icon.X />
                    </InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
    }

    return view
}

export default EditText