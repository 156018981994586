import { Card, Col, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { IUser } from "../../../Types"
import UserAvatar from "../../UserAvatar"

function OtherUsers({ users, invite }: { users?: IUser[], invite: (email: string, userId: string) => void }) {

    return (
        <>
            {users?.length ? <h3>Invite more</h3> : null}
            {users?.map(user => {
                return (
                    <Card
                        key={user.email}
                        className="mb-3"
                    >
                        <Card.Body>
                            <Row>
                                {user?.name ? <Col xs="auto"><UserAvatar user={{
                                    name: user.name,
                                    picture: user.picture,
                                }} /></Col> : null}
                                <Col>
                                    <Row>
                                        <Col>{user.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="small">{user.email}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Icon.Share onClick={() => invite(user.email, user.userId)} />
                        </Card.Footer>
                    </Card>
                )
            })}
        </>
    )
}

export default OtherUsers