import { Breadcrumb, Button, Col, Container, ListGroup, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useListEventsQuery } from "../../Store/Event/Event.service"
import * as Icon from 'react-bootstrap-icons'
import EventListItem from "../EventListItem"

function Events() {

    const navigate = useNavigate()
    const eventsQuery = useListEventsQuery()

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Events</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>My events</h3>
                </Col>
                <Col className="text-end">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate('/app/events-add')}
                    >
                        <Icon.Plus />
                    </Button>
                </Col>
            </Row>
            {eventsQuery.data ? <Row>
                <Col>
                    <ListGroup>
                        {eventsQuery.data.map(event => <EventListItem
                            key={event._id}
                            event={event}
                            canEdit={true}
                            isShared={false}
                            />)}
                    </ListGroup>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default Events