import { Container } from "react-bootstrap"

function Main() {

    return (
        <Container>
            <div>Main</div>
        </Container>
    )
}

export default Main