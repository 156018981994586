import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

function Welcome() {

    const navigate = useNavigate()
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    let view = <Container>
        <Row>
            <Col><h1>Welcome to Shopping List Application</h1></Col>
        </Row>
        <Row className="mb-3">
            <Col>You can manage and share your shopping lists easily and quickly.</Col>
        </Row>
        <Row>
            <Col>
                <Button
                    variant="primary"
                    onClick={() => {
                        isAuthenticated ? navigate('/app') : loginWithRedirect()
                    }}
                >
                    Get Started
                </Button>
            </Col>
        </Row>
    </Container>

    return view
}

export default Welcome