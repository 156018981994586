import { skipToken } from "@reduxjs/toolkit/query"
import { Badge, Breadcrumb, Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useDeleteSharedListProductMutation, useGetSharedEventQuery, useGetSharedShoppingListQuery, useListSharedListProductsQuery, useMarkSharedListProductDoneMutation, useUpdateSharedListProductMutation } from "../../Store/Event/Event.service"
import { useEffect, useMemo, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { CurrencyService } from "../../Services/Currency"
import { NumericFormat } from "react-number-format"
import { ApiError } from "../../Services/BaseApi"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import UserAvatar from "../UserAvatar"
import EventUserAvatars from "../EventUserAvatars"
import { IListProduct, Member } from "../../Types"

function SahredSLists() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { eventId, listId } = useParams()

    const eventArgs = eventId ?? skipToken
    const eventQuery = useGetSharedEventQuery(eventArgs)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetSharedShoppingListQuery(listArgs)
    const productsQuery = useListSharedListProductsQuery(listArgs)

    const userArgs = listQuery.data?.assigneeUserId ? listQuery.data.assigneeUserId : skipToken

    const ownerArgs = listQuery.data?.userId ? listQuery.data.userId : skipToken
    const ownerQuery = useGetUserQuery(ownerArgs)

    const [deleteListProduct] = useDeleteSharedListProductMutation()
    const [markDone] = useMarkSharedListProductDoneMutation()
    const [updateProduct] = useUpdateSharedListProductMutation()

    const [selected, setSelected] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState(false)

    const [editProduct, setEditProduct] = useState<IListProduct | null>(null)
    const [showEdit, setShowEdit] = useState(false)

    const members = useMemo(() => {
        if (
            eventQuery.isLoading ||
            !eventQuery.isSuccess ||
            listQuery.isLoading ||
            !listQuery.isSuccess
        ) {
            return []
        }
        const allMembers: any = {}
        if (eventQuery.data?.members?.length) {
            for (const member of eventQuery.data.members) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        if (listQuery.data?.members?.length) {
            for (const member of listQuery.data.members) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        let allMembersValues: Member[] = []
        if (Object.keys(allMembers).length > 0) {
            allMembersValues = Object.values(allMembers)
        }
        const members = allMembersValues.map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [
        eventQuery.isLoading,
        eventQuery.isSuccess,
        eventQuery.data,
        listQuery.isLoading,
        listQuery.isSuccess,
        listQuery.data,
    ])

    useEffect(() => {
        if (selected.length && productsQuery.data && selected.length === productsQuery.data.length) {
            setAllSelected(true)
        }
        if (!selected.length) {
            setAllSelected(false)
        }
    }, [selected, productsQuery.data])

    const toggleSelectAll = () => {
        if (selected.length) {
            setSelected([])
            setAllSelected(false)
        } else {
            let s: string[] = []
            if (productsQuery.data) {
                s = productsQuery.data?.map(p => p._id)
            }
            setSelected(s)
            setAllSelected(true)
        }
    }

    const deleteSelectedProducts = async () => {
        if (selected.length && eventId && listId) {
            const confirm = window.confirm(`Delete ${selected.length} shopping list products?`)
            if (confirm) {
                try {
                    for (const selectedProductId of selected) {
                        await deleteListProduct({
                            eventId,
                            listId,
                            productId: selectedProductId,
                        })
                        setSelected(selected.filter(p => p !== selectedProductId))
                    }
                } catch (err) {
                    dispatch(setMessage((err as ApiError).data.error))
                }
            }
        }
    }

    const onEdit = (product: IListProduct) => {
        setEditProduct(product)
        setShowEdit(true)
    }

    const onSelect = (product: IListProduct) => {
        if (selected.includes(product._id)) {
            setSelected(selected.filter(p => p !== product._id))
            setAllSelected(false)
        } else {
            setSelected([...selected, product._id])
        }
    }

    const onMark = async (product: IListProduct) => {
        try {
            await markDone({
                eventId: product.eventId,
                listId: product.listId,
                ids: [product._id],
                isDone: product.status !== "COMPLETE",
            })
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onMarkMany = async ({
        eventId,
        listId,
        productIds,
        isDone,
    }: {
        eventId: string
        listId: string
        productIds: string[]
        isDone: boolean
    }) => {
        try {
            await markDone({
                eventId,
                listId,
                ids: productIds,
                isDone,
            })
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async (product: IListProduct) => {
        const confirm = window.confirm(`Delte product ${product.title} from the shopping list?`)
        if (confirm) {
            try {
                await deleteListProduct({
                    eventId: product.eventId,
                    listId: product.listId,
                    productId: product._id,
                })
                dispatch(setMessage('Product removed'))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const onQtyChange = async (product: IListProduct, qty: number) => {
        try {
            await updateProduct({
                eventId: product.eventId,
                listId: product.listId,
                productId: product._id,
                qty,
            }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/shared-events')}>Shared Events</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/shared-events/' + eventId)}>{ownerQuery.data ? <UserAvatar user={ownerQuery.data} /> : null} {eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{listQuery.data?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{listQuery.data?.title}</h1>
                    <div>{listQuery.data?.description}</div>
                </Col>
            </Row>
            {members.length ? <Row className="mb-3">
                <Col>
                    <EventUserAvatars users={members.map(member => member)} />
                </Col>
            </Row> : null}
            <Row className="mb-3">
                <Col>
                    <h3>Product List</h3>
                </Col>
                <Col className="text-end">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate(`/app/shared-events/${eventId}/shared-lists/${listId}/shared-products-add`)}
                    >
                        <Icon.Plus />
                    </Button>
                </Col>
            </Row>
            {selected.length ? <Row className="mb-3">
                <Col>
                    <div className="d-grid gap-2">
                        <ButtonGroup>
                            <Button variant="danger" onClick={deleteSelectedProducts}><Icon.Trash /></Button>
                            <Button variant="success" onClick={() => {
                                if (!eventId || !listId) {
                                    return
                                }
                                onMarkMany({
                                    eventId,
                                    listId,
                                    productIds: selected,
                                    isDone: true,
                                })
                            }}><Icon.Check /></Button>
                            <Button variant="secondary" onClick={() => {
                                if (!eventId || !listId) {
                                    return
                                }
                                onMarkMany({
                                    eventId,
                                    listId,
                                    productIds: selected,
                                    isDone: false,
                                })
                            }}><Icon.X /></Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row> : null}
            {productsQuery.data?.length ? <Row className="mb-3">
                <Col><label><input type="checkbox" onChange={toggleSelectAll} checked={allSelected} /> Select all</label></Col>
            </Row> : null}
            {productsQuery.data?.length ? <Row>
                <Col>
                    {productsQuery.data?.map(product => <ProductItem
                        product={product}
                        key={product._id}
                        isSelected={selected.includes(product._id)}
                        onEdit={onEdit}
                        onSelect={onSelect}
                        onMark={onMark}
                        onDelete={onDelete}
                        onQtyChange={onQtyChange}
                    />)}
                </Col>
            </Row> : <Row>
                <Col>
                    No products
                </Col>
            </Row>}
        </Container>
    )
}

export default SahredSLists

function ProductItem({
    product,
    isSelected,
    onSelect,
    onEdit,
    onMark,
    onDelete,
    onQtyChange,
}: {
    product: IListProduct
    isSelected: boolean
    onSelect: (product: IListProduct) => void
    onEdit: (product: IListProduct) => void
    onMark: (product: IListProduct) => void
    onDelete: (product: IListProduct) => void
    onQtyChange: (product: IListProduct, qty: number) => void
}) {
    let weight = ''
    if (product.weightGr) {
        weight = `${product.weightGr}`
    }

    let symbol = '$'
    if (product.currency) {
        const currency = CurrencyService.getCurrency(product.currency)
        if (currency) {
            symbol = currency.symbol
        }
    }

    let titleStyle = undefined
    if (product.status === "COMPLETE") {
        titleStyle = { textDecoration: 'line-through' }
    }

    let cardBorder = undefined
    if (product.status === "COMPLETE") {
        cardBorder = "success"
    }

    return (
        <Row key={product._id} className="mb-3">
            <Col xs="auto" className="text-center">
                <input type="checkbox" checked={isSelected} onChange={() => onSelect(product)} />
            </Col>
            <Col>
                <Card
                    border={cardBorder}
                >
                    {product.imageUrl ? <Card.Img
                        variant="top"
                        src={product.imageUrl}
                    /> : null}
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="fw-bold" style={titleStyle}>{product.title}</div>
                                <div className="small">{product.description}</div>
                            </Col>
                            {product.qty ? <Col xs="auto">
                                <Row>
                                    <Col className="text-center">
                                        <Icon.CaretUp
                                            className="text-secondary"
                                            onClick={() => onQtyChange(product, product.qty ? product.qty + 1 : 1)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Badge
                                            pill
                                            bg="secondary"
                                        >
                                            <NumericFormat
                                                value={product.qty}
                                                displayType="text"
                                                thousandSeparator={true}
                                                decimalScale={2}
                                            />
                                        </Badge>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Icon.CaretDown
                                            className="text-secondary"
                                            onClick={() => onQtyChange(product, product.qty && product.qty > 1 ? product.qty - 1 : 1)}
                                        />
                                    </Col>
                                </Row>
                            </Col> : null}
                        </Row>
                        <Row>
                            {product.unitPrice ? <Col xs="auto">
                                <Row>
                                    <Col className="small">Price</Col>
                                </Row>
                                <Row>
                                    <Col className="fs-4">
                                        <NumericFormat
                                            value={product.qty ? product.unitPrice * product.qty : product.unitPrice}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={symbol}
                                        />
                                    </Col>
                                </Row>
                            </Col> : null}
                            {weight ? <Col xs="auto">
                                <Row>
                                    <Col className="small">Weight</Col>
                                </Row>
                                <Row>
                                    <Col className="fs-3">
                                        <NumericFormat
                                            value={weight}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            suffix={'gr'}
                                        />
                                    </Col>
                                </Row>
                            </Col> : null}
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            {product.status === "PENDING" ?
                                <Col xs="auto"><Icon.Check onClick={() => onMark(product)} className="text-success" /></Col> :
                                <Col xs="auto"><Icon.X onClick={() => onMark(product)} className="text-secondary" /></Col>}
                            <Col xs="auto"><Icon.Pencil onClick={() => onEdit(product)} className="text-secondary" /></Col>
                            <Col className="text-end"><Icon.Trash onClick={() => onDelete(product)} className="text-danger" /></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
}