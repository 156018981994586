interface IConfig {
    isDev(): boolean
    SERVER_BASE_URL: string
    REACT_APP_AUTH0_DOMAIN: string
    REACT_APP_AUTH0_CLIENT_ID: string
    REACT_APP_AUTH0_AUD: string
    REACT_APP_VAPIDKEY: string
}

export const Config: IConfig = {
    isDev,
    SERVER_BASE_URL: isDev() ? 'http://localhost:3001' : 'https://slapi.lzdnet.com',
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    REACT_APP_AUTH0_AUD: process.env.REACT_APP_AUTH0_AUD ?? '',
    REACT_APP_VAPIDKEY: process.env.REACT_APP_VAPIDKEY ?? '',
}

function isDev(): boolean {
    return process.env.NODE_ENV === 'development'
}