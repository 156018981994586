import * as ls from "local-storage"

export enum LocalStorageKeys {
    AUTH_TOKEN = '@auth_token',
    USER_ID = '@user_id',
}

interface ILocalStorageService {
    getItem(key: string): Promise<string | null>;
    setItem(key: string, value: any): Promise<void>;
    removeItem(key: string): Promise<void>;
}

const LocalStorageService: ILocalStorageService = {
    getItem,
    setItem,
    removeItem,
};

export default LocalStorageService;

async function getItem(key: string): Promise<string | null> {
    try {
        return ls.get(key);
    } catch (err) {
        return null;
    }
}

async function setItem(key: string, value: any): Promise<void> {
    try {
        ls.set(key, value);
    } catch (err) {
        return;
    }
}

async function removeItem(key: string): Promise<void> {
    try {
        ls.remove(key);
    } catch (err) {
        return;
    }
}