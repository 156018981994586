import { Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

function Invite({ invite }: { invite: (email: string) => void }) {

    const [email, setEmail] = useState('')

    const initState = {
        email: '',
    }

    const [initialValues] = useState(initState)

    const {
        register,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    const vEmail = watch("email")

    useEffect(() => {
        setEmail(vEmail.trim())
    }, [vEmail])

    return (
        <>

            <Form>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Control
                        type="email"
                        placeholder="Type member's email address"
                        {...register("email", {
                            required: "Email is required",
                            validate: {
                                maxLength: (v) =>
                                    v.length <= 50 || "The email should have at most 50 characters",
                                matchPattern: (v) =>
                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                    "Email address must be a valid address",
                            },
                        })}
                    />
                    {errors.email && (
                        <Form.Text className="text-danger">
                            {errors.email.message}
                        </Form.Text>
                    )}
                </Form.Group>
            </Form>

            {email && isValid ?
                <div className="d-grid gap-2 mb-3">
                    <Button
                        variant="primary"
                        onClick={() => {
                            invite(email)
                            reset()
                        }}
                    >
                        Invite {email}
                    </Button>
                </div> : null}
        </>
    )
}

export default Invite