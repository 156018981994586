import { IEvent, IMember } from "../../../Types"
import { ListGroup } from "react-bootstrap"
import EditText from "../../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { useAddEventInvitationMutation, useDeleteEventInvitationMutation, useUpdateEventMutation } from "../../../Store/Event/Event.service"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"
import EditMembers from "../../EditResource/EditMembers"

function EditEventPage({ event }: { event: IEvent }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateEvent, updateEventResult] = useUpdateEventMutation()

    const [addEventInvitation] = useAddEventInvitationMutation()
    const [deleteEventInvitation] = useDeleteEventInvitationMutation()

    const handleUpdate = async (value: string | number, key: "title" | "description") => {
        if (!event) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateEvent({ eventId: event._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const unshare = async (email: string) => {
        if (!email) {
            return
        }
        try {
            await deleteEventInvitation({ eventId: event._id, email }).unwrap()
            dispatch(setMessage('Invitation removed'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const invite = async (email: string, userId?: string) => {
        if (!email) {
            return
        }
        try {
            await addEventInvitation({ eventId: event._id, email, userId }).unwrap()
            dispatch(setMessage('Invitation sent'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <ListGroup>
            <ListGroup.Item>
                <EditText
                    icon={<Icon.Fonts />}
                    disabled={updateEventResult.isLoading}
                    defaultValue={event.title}
                    placeholder="Event title"
                    handleUpdate={text => handleUpdate(text, "title")}
                />
            </ListGroup.Item>
            <ListGroup.Item>
                <EditTextarea
                    icon={<Icon.CardText />}
                    disabled={updateEventResult.isLoading}
                    defaultValue={event.description}
                    placeholder="Add description"
                    handleUpdate={text => handleUpdate(text, "description")}
                />
            </ListGroup.Item>
            <ListGroup.Item>
                <EditMembers
                    defaultValue={event.members}
                    unshare={unshare}
                    invite={invite}
                />
            </ListGroup.Item>
        </ListGroup>
    )
}

export default EditEventPage