import UserAvatar from "../UserAvatar"

function EventUserAvatars({ users }: {
    users: ({
        userId: string
        name: string
        picture?: string
    } | undefined)[]
}) {
    let view = null

    if (users.length) {
        view = users.map(user => {
            if (!user) {
                return null
            }
            return (
                <span
                    key={user.userId}
                    className="me-1 mb-1"
                >
                    <UserAvatar user={user} />
                </span>
            )
        })
    }

    return (
        <>
            {view}
        </>
    )
}

export default EventUserAvatars