import { skipToken } from "@reduxjs/toolkit/query"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAcceptShoppingListInvitationMutation, useValidateShoppingListInvitationQuery } from "../../Store/Event/Event.service"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { ApiError } from "../../Services/BaseApi"
import { setMessage } from "../../Store/Toast/Toast.slice"
import Loading from "../Loading"

function ListInvitation() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { token } = useParams()

    const args = token ? token : skipToken
    const { data, isLoading, isSuccess } = useValidateShoppingListInvitationQuery(args)
    const [acceptInvitation, acceptInvitationResult] = useAcceptShoppingListInvitationMutation()

    const [title, setTitle] = useState<string>()
    const [text, setText] = useState<string>('Invalid invitation')

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setTitle(data.title)
            setText(`You are invited to be a member of a Shopping List by`)
            if (data.status === "ACCEPTED") {
                navigate(`/app/shared-events/${data.eventId}/shared-lists/${data.listId}`)
            }
        }
    }, [data, isLoading, isSuccess])

    const handleAccept = async () => {
        if (!token || !data) {
            return
        }
        try {
            await acceptInvitation(token).unwrap()
            navigate(`/app/shared-events/${data.eventId}/shared-lists/${data.listId}`)
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
            navigate(`/app/shared-events`)
        }
    }

    return (
        <Container>
            {isLoading ? <Row className="text-center">
                <Col><Loading /></Col>
            </Row> : null}
            {!isLoading ? <Row className="text-center">
                <Col className="fs-3">{title}</Col>
            </Row> : null}
            {!isLoading ? <Row className="text-center">
                <Col>{text} <span className="fw-bold">{data?.inviterName}</span></Col>
            </Row> : null}
            {!isLoading && isSuccess && data?.status === "PENDING" ? <Row className="text-center mt-3">
                <Col>
                    <Button
                        disabled={acceptInvitationResult.isLoading}
                        variant="primary"
                        onClick={handleAccept}
                    >
                        {acceptInvitationResult.isLoading ? <Loading /> : 'Accept Invitation'}
                    </Button>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default ListInvitation