import { Button, ListGroup, Offcanvas } from "react-bootstrap"
import { IStore } from "../../../Types"
import EditText from "../../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import { useDeleteStoreMutation, useUpdateStoreMutation } from "../../../Store/Event/Event.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"

function EditStore({ show, handleClose, store }: { show: boolean, handleClose: () => void, store: IStore }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateStore, updateStoreResult] = useUpdateStoreMutation()
    const [deleteStore, deleteStoreResult] = useDeleteStoreMutation()

    const handleUpdate = async (value: string, key: "name" | "description" | "address") => {
        if (!store) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateStore({ storeId: store._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async () => {
        const confirm = window.confirm(`Delete product ${store.name}?`)
        if (confirm) {
            if (!store) {
                return
            }
            try {
                await deleteStore(store._id).unwrap()
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Store</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup>
                    <ListGroup.Item>
                        <EditText
                            icon={<Icon.Fonts />}
                            disabled={updateStoreResult.isLoading}
                            defaultValue={store.name}
                            placeholder="Store name"
                            handleUpdate={text => handleUpdate(text, "name")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditTextarea
                            icon={<Icon.CardText />}
                            disabled={updateStoreResult.isLoading}
                            defaultValue={store.description}
                            placeholder="Add description"
                            handleUpdate={text => handleUpdate(text, "description")}
                        />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <EditText
                            icon={<Icon.GeoAlt />}
                            disabled={updateStoreResult.isLoading}
                            defaultValue={store.address}
                            placeholder="Add address"
                            handleUpdate={text => handleUpdate(text, "address")}
                        />
                    </ListGroup.Item>
                </ListGroup>

                <div className="d-grid gap-2 mt-3">
                    <Button
                        type='submit'
                        variant="danger"
                        disabled={deleteStoreResult.isLoading}
                        onClick={onDelete}
                    ><Icon.Trash /> Delete</Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default EditStore