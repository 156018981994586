import { Col, Form, InputGroup, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { ReactNode, useState } from "react"

function EditNumber({
    defaultValue,
    placeholder,
    icon,
    right,
    disabled,
    handleUpdate,
}: {
    defaultValue?: number
    placeholder?: string
    icon: ReactNode
    right?: ReactNode
    disabled?: boolean
    handleUpdate: (num: number) => void
}) {

    const [edit, setEdit] = useState(false)
    const [num, setNum] = useState<number>(defaultValue ?? 0)

    const handleSave = (num: number) => {
        setEdit(false)
        handleUpdate(num)
    }

    let className: string | undefined = "text-secondary"
    if (defaultValue) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{defaultValue ? defaultValue : placeholder ? placeholder : ''}</Col>
        {right ? <Col className={className} xs={'auto'}>{right}</Col> : null}
    </Row>
    if (edit) {
        view = <Row>
            <Col xs='auto'>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="number"
                        placeholder={placeholder ?? ''}
                        defaultValue={defaultValue}
                        onChange={e => setNum(+e.target.value)}
                        disabled={disabled}
                    />
                    <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => handleSave(num)}
                    >
                        <Icon.Check />
                    </InputGroup.Text>
                    <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => setEdit(false)}
                    >
                        <Icon.X />
                    </InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
    }

    return view
}

export default EditNumber