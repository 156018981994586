import { Accordion, Breadcrumb, Col, Container, ListGroup, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useListSharedEventListsQuery, useListSharedEventsQuery } from "../../Store/Event/Event.service"
import { useEffect, useState } from "react"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import UserAvatar from "../UserAvatar"

function SharedEvents() {

    const navigate = useNavigate()

    const eventsQuery = useListSharedEventsQuery()
    const sharedListsEventsQuery = useListSharedEventListsQuery()

    const [items, setItems] = useState<{
        eventId: string
        title: string
        description?: string
        userId: string
        lists: {
            listId: string
            title: string
        }[]
    }[]>([])

    useEffect(() => {
        if (
            !eventsQuery.isLoading &&
            eventsQuery.isSuccess &&
            eventsQuery.data &&
            !sharedListsEventsQuery.isLoading &&
            sharedListsEventsQuery.isSuccess &&
            sharedListsEventsQuery.data
        ) {
            const items1Index: string[] = eventsQuery.data.map(eventData => eventData._id)
            const items1 = eventsQuery.data.map(eventData => {
                return {
                    eventId: eventData._id,
                    title: eventData.title,
                    description: eventData.description,
                    userId: eventData.userId,
                    lists: eventData.lists?.map(listData => {
                        return {
                            listId: listData._id,
                            title: listData.title,
                        }
                    })
                }
            })
            const items2 = sharedListsEventsQuery.data
                .filter(eventData => !items1Index.includes(eventData._id))
                .map(eventData => {
                    return {
                        eventId: eventData._id,
                        title: eventData.title,
                        description: eventData.description,
                        userId: eventData.userId,
                        lists: eventData.lists?.map(listData => {
                            return {
                                listId: listData._id,
                                title: listData.title,
                            }
                        })
                    }
                })
            setItems([...items1, ...items2])
        }
    }, [
        eventsQuery.isLoading,
        eventsQuery.isSuccess,
        eventsQuery.data,
        sharedListsEventsQuery.isLoading,
        sharedListsEventsQuery.isSuccess,
        sharedListsEventsQuery.data,
    ])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Shared Events</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>Events shared with me</h3>
                </Col>
            </Row>
            {items ? <Row>
                <Col>
                    <Accordion defaultActiveKey="0" flush>
                        {items.map((item, i) => <SharedListEventItem
                            key={item.eventId}
                            index={i.toString()}
                            eventId={item.eventId}
                            title={item.title}
                            description={item.description}
                            userId={item.userId}
                            lists={item.lists}
                        />)}
                    </Accordion>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default SharedEvents

function SharedListEventItem({
    index,
    eventId,
    title,
    description,
    userId,
    lists,
}: {
    index: string
    eventId: string
    title: string
    description?: string
    userId: string
    lists: {
        listId: string
        title: string
    }[]
}) {

    const navigate = useNavigate()

    const userQuery = useGetUserQuery(userId)

    return (
        <Accordion.Item
            key={eventId}
            eventKey={index}
        >
            <Accordion.Header>{userQuery.data ? <UserAvatar user={userQuery.data} /> : null}&nbsp;{title}</Accordion.Header>
            <Accordion.Body>
                {description ? <Row className="mb-3">
                    <Col>{description}</Col>
                </Row> : null}
                <Row>
                    <Col
                        key={eventId}
                    >
                        <ListGroup>
                            {lists.map(listData => <ListGroup.Item
                                key={listData.listId}
                                onClick={() => navigate(`/app/shared-events/${eventId}/shared-lists/${listData.listId}`)}
                            >
                                {listData.title}
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}