import moment from "moment"

interface IDateService {
    formatDate(date?: string | Date, format?: string): string
}

export const DateService: IDateService = {
    formatDate,
}

function formatDate(date?: string | Date, format: string = "LLL") {
    if (!date) {
        return ''
    }
    return moment(date).format(format)
}