import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetEventQuery, useGetShoppingListQuery } from "../../Store/Event/Event.service"
import EditShoppingListPage from "./Edit/index"

function EditShoppingList() {

    const navigate = useNavigate()
    const { eventId, listId } = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const backUrl = searchParams.has('r') ? searchParams.get('r') : null

    const args = eventId ?? skipToken
    const eventQuery = useGetEventQuery(args)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetShoppingListQuery(listArgs)

    const event = eventQuery.data ?? null
    const list = listQuery.data ?? null

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>Events</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId)}>{eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId + '/lists/' + listId)}>{listQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Edit Shopping List</h3>
                </Col>
            </Row>
            {event && list ? <Row>
                <Col>
                    <EditShoppingListPage event={event} list={list} />
                    {backUrl ? <div className="text-center">
                        <Button
                            variant="link"
                            onClick={() => navigate(backUrl)}
                        >
                            Back
                        </Button>
                    </div> : null}
                </Col>
            </Row> : null}
        </Container>
    )
}

export default EditShoppingList