import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IToastState } from './Types'

const initialState: IToastState = {
    message: null,
    show: false,
}

export const toastSlice = createSlice({
    name: 'Toast',
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<string | null>) {
            state.message = action.payload
            state.show = true
        },
        hideMessage(state) {
            state.show = false
        },
    },
})

export const {
    setMessage,
    hideMessage,
} = toastSlice.actions
export default toastSlice.reducer