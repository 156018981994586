import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProductState } from './Types'

const initialState: ProductState = {
    showProductFinder: false,
}

export const productSlice = createSlice({
    name: 'Product',
    initialState,
    reducers: {
        showProductFinder(state, action: PayloadAction<boolean>) {
            state.showProductFinder = action.payload
        },
    },
})

export const {
    showProductFinder,
} = productSlice.actions
export default productSlice.reducer