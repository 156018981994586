import React, { useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { useForm } from "react-hook-form"
import ProductFinder from "./product-finder"
import { AppDispatch } from "../../Store/Store"
import { useDispatch } from "react-redux"
import { showProductFinder } from "../../Store/Product/Product.slice"
import { currencyCodes, currencyCodesType, IProduct, IStore } from "../../Types"
import { useLazyGetStoreByIdQuery } from "../../Store/Store/Store.service"
import { setMessage } from "../../Store/Toast/Toast.slice"
import StoreFinder from "./store-finder"
import { showStoreFinder } from "../../Store/Store/Store.slice"
import { CurrencyService } from "../../Services/Currency"
import { ApiError } from "../../Services/BaseApi"

export type AddListProductFormData = {
    title: string
    productId?: string
    description?: string
    storeId?: string
    storeName?: string
    qty?: number
    unitPrice?: number
    weightGr?: number
    currency?: string
}

interface IAddListProductFormProps {
    handleSubmit(data: AddListProductFormData): void
    disabled: boolean
    currencyCode: currencyCodesType
}

const AddListProductForm: React.FC<IAddListProductFormProps> = (props: IAddListProductFormProps) => {

    const dispatch = useDispatch<AppDispatch>()

    const [getStoreById] = useLazyGetStoreByIdQuery()

    const [showAdditional, setShowAdditional] = useState(false)

    const initState = {
        title: "",
        productId: "",
        description: "",
        storeId: "",
        storeName: "",
        qty: 1,
        unitPrice: 0,
        weightGr: 0,
        currency: props.currencyCode,
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: AddListProductFormData) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        trigger,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    const onProductSelect = async (product: IProduct) => {
        dispatch(showProductFinder(false))
        setValue('title', product.title)
        setValue('description', product.description ?? '')
        setValue('productId', product._id)
        setValue('weightGr', product.weightGr ?? 0)
        setValue('unitPrice', product.unitPrice ?? 0)

        if (product.storeId) {
            try {
                const store = await getStoreById(product.storeId).unwrap()
                if (store) {
                    setValue('storeId', store._id)
                    setValue('storeName', store.name)
                }
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }

        trigger()
    }

    const onStoreSelect = async (store: IStore) => {
        dispatch(showStoreFinder(false))
        if (store._id) {
            setValue('storeId', store._id)
        }
        setValue('storeName', store.name)

        trigger()
    }

    const decQty = () => {
        const values = getValues()
        let qty = values.qty
        if (qty - 1 > 0) {
            qty--
        }
        setValue('qty', qty)
    }

    const incQty = () => {
        const values = getValues()
        let qty = values.qty + 1
        setValue('qty', qty)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="My new product"
                    onClick={() => dispatch(showProductFinder(true))}
                    {...register("title", { required: "Title is required" })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>
            <ProductFinder onProductSelect={onProductSelect} />
            <Form.Group className="mb-3" controlId="qty">
                <Form.Label>Qty</Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Text
                        onClick={decQty}
                    >-</InputGroup.Text>
                    <Form.Control
                        type="number"
                        style={{ textAlign: 'center' }}
                        {...register("qty")}
                    />
                    <InputGroup.Text
                        onClick={incQty}
                    >+</InputGroup.Text>
                </InputGroup>
            </Form.Group>

            {!showAdditional ? <div className="d-grid gap-2 mb-3">
                <Button
                    type='button'
                    variant="secondary"
                    disabled={props.disabled}
                    onClick={() => setShowAdditional(true)}
                >Show Additional Fields</Button>
            </div> : null}

            {showAdditional ? <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    type="text"
                    multiple
                    placeholder="Product description"
                    {...register("description")}
                />
            </Form.Group> : null}
            {showAdditional ? <Form.Group className="mb-3" controlId="storeName">
                <Form.Label>Store</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Store name"
                    onClick={() => dispatch(showStoreFinder(true))}
                    {...register("storeName")}
                />
            </Form.Group> : null}
            {showAdditional ? <StoreFinder onStoreSelect={onStoreSelect} /> : null}
            {showAdditional ? <Form.Group className="mb-3" controlId="unitPrice">
                <Form.Label>Unit Price</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="$"
                    {...register("unitPrice")}
                />
            </Form.Group> : null}
            {showAdditional ? <Form.Group className="mb-3" controlId="weightGr">
                <Form.Label>Weight (gr)</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="0gr"
                    {...register("weightGr")}
                />
            </Form.Group> : null}
            {showAdditional ? <Form.Group className="mb-3" controlId="currency">
                <Form.Label>Currency</Form.Label>
                <Form.Select
                    {...register("currency")}
                >
                    {currencyCodes.map(code => {
                        const c = CurrencyService.getCurrency(code)
                        if (!c) {
                            return null
                        }
                        return (
                            <option key={code} value={code}>{c?.symbol} {c?.name}</option>
                        )
                    })}
                </Form.Select>
            </Form.Group> : null}

            {showAdditional ? <div className="d-grid gap-2 mb-3">
                <Button
                    type='button'
                    variant="secondary"
                    disabled={props.disabled}
                    onClick={() => setShowAdditional(false)}
                >Hide Additional Fields</Button>
            </div> : null}

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >Add</Button>
            </div>
        </Form>

    )
}

export default AddListProductForm