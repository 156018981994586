import { useNavigate } from "react-router-dom"
import { Card, Col, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { IShoppingList, Member } from "../../Types"
import { useMemo } from "react"
import EventUserAvatars from "../EventUserAvatars"

function ShoppingListItem({ eventId, list, canEdit, isShared }: { eventId: string, list: IShoppingList, canEdit: boolean, isShared: boolean }) {

    const navigate = useNavigate()

    const members = useMemo(() => {
        let members: Member[] = []
        if (list.members?.length) {
            members = list.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [list.members])

    return (
        <Card
            className="mb-3"
        >
            {list.imageUrl ? <Card.Img
                onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}
                src={list.imageUrl}
            /> : null}
            <Card.Body>
                <Row>
                    <Col className="fw-bold" onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}>{list.title}</Col>
                </Row>
                {list.description ? <Row>
                    <Col className="small">{list.description}</Col>
                </Row> : null}
            </Card.Body>
            <Card.Footer>
                <Row>
                    {members.length ? <Col>
                        <EventUserAvatars users={members} />
                    </Col> : null}
                    {canEdit ? <Col className="text-end">
                        <Icon.Pencil
                            onClick={() => {
                                navigate(`/app/events/${eventId}/lists-edit/${list._id}?r=/app/events/${eventId}`)
                            }}
                        />
                    </Col> : null}
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default ShoppingListItem