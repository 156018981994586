import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUserConfigState } from './Types'
import { countryCodesType, currencyCodesType } from '../../Types'
import { api } from './UserConfig.service'

const initialState: IUserConfigState = {
    user: null,
    countryCode: 'US',
    currencyCode: 'USD',
}

export const userConfigSlice = createSlice({
    name: 'UserConfig',
    initialState,
    reducers: {
        setCountryCode(state, action: PayloadAction<countryCodesType>) {
            state.countryCode = action.payload
        },
        setCurrencyCode(state, action: PayloadAction<currencyCodesType>) {
            state.currencyCode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.getUserConfig.matchPending,
                (state) => {
                    state.user = null
                }
            )
            .addMatcher(
                api.endpoints.getUserConfig.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload
                }
            )
            .addMatcher(
                api.endpoints.getUserConfig.matchRejected,
                (state) => {
                    state.user = null
                }
            )
    }
})

export const {
    setCountryCode,
    setCurrencyCode,
} = userConfigSlice.actions
export default userConfigSlice.reducer