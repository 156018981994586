import { IEvent, IMember, IShoppingList, Member, Value } from "../../../Types"
import { ListGroup } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import EditText from "../../EditResource/EditText"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import { useAddShoppingListInvitationMutation, useDeleteShoppingListInvitationMutation, useUpdateShoppingListMutation } from "../../../Store/Event/Event.service"
import EditTextarea from "../../EditResource/EditTextarea"
import EditMembers from "../../EditResource/EditMembers"
import EditDateTime from "../../EditResource/EditDateTime"
import EditList from "../../EditResource/EditList"
import { useMemo } from "react"
import { useAuth0 } from "@auth0/auth0-react"

function EditShoppingListPage({ event, list }: { event: IEvent, list: IShoppingList }) {

    const dispatch = useDispatch<AppDispatch>()

    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [addShoppingListInvitation] = useAddShoppingListInvitationMutation()
    const [deleteShoppingListInvitation] = useDeleteShoppingListInvitationMutation()

    const { user } = useAuth0();

    const members = useMemo(() => {
        let members: Member[] = []
        if (list.members?.length) {
            members = list.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }
        if (user) {
            members = [{
                userId: user.sub ?? '',
                email: user.email ?? '',
                name: user.name ?? '',
                picture: user.picture,
                isMe: true,
            }, ...members]
        }
        return members
    }, [list.members, user])

    const handleUpdate = async (value: string | number | Value, key: "title" | "description" | "scheduleAt" | "assigneeUserId") => {
        if (!event) {
            return
        }
        try {
            const q: { [index: string]: string | number | Value } = {}
            q[key] = value
            await updateShoppingList({ eventId: event._id, listId: list._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const unshare = async (email: string) => {
        if (!list) {
            return
        }
        try {
            await deleteShoppingListInvitation({ listId: list._id, email }).unwrap()
            dispatch(setMessage('Invitation removed'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const invite = async (email: string, userId?: string) => {
        if (!email) {
            return
        }
        try {
            await addShoppingListInvitation({ listId: list._id, email, userId }).unwrap()
            dispatch(setMessage('Invitation sent'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const getDisplayLabel = (userId: string) => {
        if (!members?.length) {
            return ''
        }
        let member: Member | null = null
        for (const memberObj of members) {
            if (memberObj.userId === userId) {
                member = memberObj
                break
            }
        }
        if (member) {
            return <>
                <div>{member.isMe ? 'You' : member.name}</div>
                {!member.isMe ? <div className="small">{member.email}</div> : null}
            </>
        }
        return ''
    }

    const getDisplayItem = (member: Member) => {
        return (
            <>
                <div>{member.isMe ? 'You' : member.name}</div>
                {!member.isMe ? <div className="small">{member.email}</div> : null}
            </>
        )
    }

    return (
        <ListGroup>
            <ListGroup.Item>
                <EditText
                    icon={<Icon.Fonts />}
                    disabled={updateShoppingListResult.isLoading}
                    defaultValue={list.title}
                    placeholder="Shopping list title"
                    handleUpdate={text => handleUpdate(text, "title")}
                />
            </ListGroup.Item>
            <ListGroup.Item>
                <EditTextarea
                    icon={<Icon.CardText />}
                    disabled={updateShoppingListResult.isLoading}
                    defaultValue={list.description}
                    placeholder="Add description"
                    handleUpdate={text => handleUpdate(text, "description")}
                />
            </ListGroup.Item>
            <ListGroup.Item>
                <EditDateTime
                    icon={<Icon.CardText />}
                    disabled={updateShoppingListResult.isLoading}
                    defaultValue={list.scheduleAt}
                    placeholder="Add schedule"
                    handleUpdate={value => handleUpdate(value, "scheduleAt")}
                />
            </ListGroup.Item>
            {members?.length ? <ListGroup.Item>
                <EditList<Member>
                    icon={<Icon.Person />}
                    disabled={updateShoppingListResult.isLoading}
                    defaultValue={list.assigneeUserId}
                    placeholder="Assign member"
                    handleUpdate={assigneeUserId => handleUpdate(assigneeUserId, "assigneeUserId")}
                    objects={members}
                    primKey="userId"
                    title="Select member"
                    isMandatory={false}
                    getDisplayLabel={getDisplayLabel}
                    getDisplayItem={getDisplayItem}
                />
            </ListGroup.Item> : null}
            <ListGroup.Item>
                <EditMembers
                    defaultValue={list.members}
                    unshare={member => {
                        unshare(member)
                    }}
                    invite={invite}
                />
            </ListGroup.Item>
        </ListGroup>
    )
}

export default EditShoppingListPage