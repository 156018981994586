import { Route, Routes, useNavigate } from 'react-router-dom'
import Navigation from "./Components/Navigation"
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { store } from './Store/Store'
import Welcome from './Components/Welcome'
import { Config } from './Config'

function App() {

    const navigate = useNavigate()

    const onRedirectCallback = (appState?: AppState) => {
        if (appState?.returnTo) {
            navigate(appState.returnTo, { replace: true, state: {
                name: appState.returnTo,
            } })

        } else {
            navigate('/app')
        }
    };

    return (
        <Provider store={store}>
            <Auth0Provider
                domain={Config.REACT_APP_AUTH0_DOMAIN ?? ''}
                clientId={Config.REACT_APP_AUTH0_CLIENT_ID ?? ''}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: Config.REACT_APP_AUTH0_AUD,
                }}
            >
                <Routes>
                    <Route path='/' element={<Welcome />} />
                    <Route path='/app/*' element={<Navigation />} />
                </Routes>
            </Auth0Provider>
        </Provider>
    )
}

export default App
