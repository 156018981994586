import moment from "moment"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type AddShoppingListFormData = {
    title: string
}

interface IAddShoppingListFormProps {
    handleSubmit(data: AddShoppingListFormData): void
    disabled: boolean
    eventId: string
}

const AddShoppingListForm: React.FC<IAddShoppingListFormProps> = (props: IAddShoppingListFormProps) => {

    const initState = {
        title: moment().format('l'),
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: AddShoppingListFormData) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="My new shopping list"
                    {...register("title", { required: "Title is required" })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >Add</Button>
            </div>
        </Form>

    )
}

export default AddShoppingListForm