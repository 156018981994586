import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Config } from '../Config'
import LocalStorageService, { LocalStorageKeys } from './LocalStorage'

export type ApiError = {
  data: {
    error: string
  },
  status: number
}

const originalBaseQuery = fetchBaseQuery({
  baseUrl: Config.SERVER_BASE_URL,
  prepareHeaders: async (headers, api) => {
    if (!headers.has('Authorization')) {
      const token: string | null = await LocalStorageService.getItem(LocalStorageKeys.AUTH_TOKEN)
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})
const wrappedBaseQuery = (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  if (true) {
    console.log("api call", args)
  }
  return originalBaseQuery(args, api, extraOptions)
}

export const baseSplitApi = createApi({
  baseQuery: wrappedBaseQuery,
  tagTypes: [
    "Events",
    "ShoppingLists",
    "ListProducts",
    "Products",
    "Stores",
    "UserConfig",
    "EventInvitations",
    "SharedEvents",
    "SharedShoppingLists",
    "SharedListProducts",
    "Users",
    "ShoppingListInvitations",
    "InvitationUsers",
    "Notifications",
  ],
  endpoints: () => ({}),
})