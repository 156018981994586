import { useEffect, useState } from "react"
import { Form, ListGroup, Offcanvas } from "react-bootstrap"
import { useListProductsQuery } from "../../Store/Event/Event.service"
import { skipToken } from "@reduxjs/toolkit/query"
import { IProduct } from "../../Types"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { showProductFinder } from "../../Store/Product/Product.slice"
import { useForm } from "react-hook-form"
import React from "react"

const FTIMEOUT = 1000

function ProductFinder({ onProductSelect }: { onProductSelect: (product: IProduct) => void }) {

    const dispatch = useDispatch<AppDispatch>()
    const [productTitle, setProductTitle] = useState('')
    const [productTitleTmp, setProductTitleTmp] = useState('')
    const [products, setProducts] = useState<IProduct[]>([])
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    const show = useSelector((state: RootState) => state.product.showProductFinder)

    const args = productTitle?.length > 1 ? productTitle.toLowerCase() : skipToken
    const productQuery = useListProductsQuery(args)

    const initState = {
        title: '',
    }

    const [initialValues] = React.useState(initState)

    const {
        register,
        setFocus,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setFocus('title')
            }, 1000)
        }
    }, [show, setFocus])

    useEffect(() => {
        if (!productQuery.isLoading) {
            if (!productQuery.data) {
                setProducts([])
            } else {
                setProducts([...productQuery.data])
            }
        }
    }, [productQuery.data, productQuery.isLoading])

    useEffect(() => {
        if (!productTitle || productTitle.length < 2) {
            setProducts([])
        }
    }, [productTitle])

    useEffect(() => {
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        const t = setTimeout(() => {
            setProductTitle(productTitleTmp)
        }, FTIMEOUT)
        setTimer(t)
    }, [productTitleTmp])

    const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
        setProductTitleTmp(e.target.value)
    }

    const handleClose = () => {
        setProductTitleTmp('')
        setProductTitle('')
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        dispatch(showProductFinder(false))
    }

    const onSelect = (product: IProduct) => {
        setProductTitleTmp('')
        setProductTitle('')
        onProductSelect(product)
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Find Product</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Control
                            type="text"
                            placeholder="Type product name"
                            {...register("title")}
                            onChange={onChangeTitle}
                        />
                    </Form.Group>
                </Form>

                <ListGroup>
                    {productTitle ? <ListGroup.Item
                        onClick={() => onSelect({
                            _id: "",
                            userId: "",
                            title: productTitle,
                        })}
                    >{productTitle}</ListGroup.Item> : null}
                    {products.map(product => <ListGroup.Item
                        key={product._id}
                        onClick={() => onSelect(product)}
                    >
                        <div>{product.title}</div>
                        <div className="small">{product.description}</div>
                    </ListGroup.Item>)}
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ProductFinder