import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap"
import { useDeleteProductsMutation, useListProductsQuery } from "../../Store/Event/Event.service"
import { NumericFormat } from "react-number-format"
import { CurrencyService } from "../../Services/Currency"
import { useEffect, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import EditProduct from "./Edit"
import { IProduct } from "../../Types"

function Products() {

    const dispatch = useDispatch<AppDispatch>()
    const productsQuery = useListProductsQuery('')

    const [selected, setSelected] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState(false)
    const [editProduct, setEditProduct] = useState<IProduct | null>(null)
    const [showEdit, setShowEdit] = useState(false)

    const [deleteProducts] = useDeleteProductsMutation()

    useEffect(() => {
        if (!productsQuery.isLoading && productsQuery.isSuccess && productsQuery.data) {
            if (editProduct) {
                for (const product of productsQuery.data) {
                    if (product._id === editProduct._id) {
                        setEditProduct({ ...product })
                        break
                    }
                }
            }
        }
    }, [productsQuery.isLoading, productsQuery.isSuccess, productsQuery.data])

    const toggleSelectAll = () => {
        if (selected.length) {
            setSelected([])
            setAllSelected(false)
        } else {
            let s: string[] = []
            if (productsQuery.data) {
                s = productsQuery.data?.map(p => p._id)
            }
            setSelected(s)
            setAllSelected(true)
        }
    }

    const deleteSelectedProducts = async () => {
        if (selected.length) {
            const confirm = window.confirm(`Delete ${selected.length} products?`)
            if (confirm) {
                try {
                    await deleteProducts(selected)
                    setSelected([])
                    setAllSelected(false)
                } catch (err) {
                    dispatch(setMessage((err as ApiError).data.error))
                }
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col><h3>My Products</h3></Col>
            </Row>
            {selected.length ? <Row className="mb-3">
                <Col>
                    <div className="d-grid gap-2">
                        <ButtonGroup>
                            <Button variant="danger" onClick={deleteSelectedProducts}><Icon.Trash /></Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row> : null}
            {productsQuery.data?.length ? <Row className="mb-3">
                <Col><label><input type="checkbox" onChange={toggleSelectAll} checked={allSelected} /> Select all</label></Col>
            </Row> : null}
            {productsQuery.data?.length ? <Row>
                <Col>
                    {editProduct ? <EditProduct
                        show={showEdit}
                        handleClose={() => {
                            setEditProduct(null)
                            setShowEdit(false)
                        }}
                        product={editProduct}
                    /> : null}
                    {productsQuery.data?.map(product => {
                        let weight = ''
                        if (product.weightGr) {
                            weight = `${product.weightGr}`
                        }

                        let symbol = '$'
                        if (product.currency) {
                            const currency = CurrencyService.getCurrency(product.currency)
                            if (currency) {
                                symbol = currency.symbol
                            }
                        }

                        return (
                            <Row key={product._id}>
                                <Col xs="auto">
                                    <input type="checkbox" checked={selected.includes(product._id)} onChange={() => {
                                        if (selected.includes(product._id)) {
                                            setSelected(selected.filter(p => p !== product._id))
                                            setAllSelected(false)
                                        } else {
                                            setSelected([...selected, product._id])
                                        }
                                    }} />
                                </Col>
                                {product.imageUrl ? <Col xs={'auto'}><img src={product.imageUrl} /></Col> : null}
                                <Col>
                                    <Row>
                                        <Col className="fw-bold">{product.title}</Col>
                                        <Col xs="auto"><Icon.Pencil onClick={() => {
                                            setEditProduct(product)
                                            setShowEdit(true)
                                        }} /></Col>
                                    </Row>
                                    <Row>
                                        {product.unitPrice ? <Col xs="auto">
                                            <Row>
                                                <Col className="small">Price</Col>
                                            </Row>
                                            <Row>
                                                <Col className="fs-3">
                                                    <NumericFormat
                                                        value={product.unitPrice}
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix={symbol}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col> : null}
                                        {weight ? <Col xs="auto">
                                            <Row>
                                                <Col className="small">Weight</Col>
                                            </Row>
                                            <Row>
                                                <Col className="fs-3">
                                                    <NumericFormat
                                                        value={weight}
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        suffix={'gr'}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col> : null}
                                    </Row>
                                    <Row>
                                        <Col>{product.description}</Col>
                                    </Row>
                                    <Row>
                                        <Col><hr /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row> : <Row>
                <Col>
                    No products
                </Col>
            </Row>}
        </Container>
    )
}

export default Products