import { Button, ButtonGroup, Col, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { ReactNode, useState } from "react"
import moment from "moment"
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Value } from "../../../Types";

function EditDateTime({
    defaultValue,
    placeholder,
    icon,
    disabled,
    handleUpdate,
}: {
    defaultValue?: Value
    placeholder?: string
    icon: ReactNode
    disabled?: boolean
    handleUpdate: (value: Value) => void
}) {

    const [edit, setEdit] = useState(false)
    const [value, onChange] = useState<Value>(defaultValue ?? new Date());

    const handleSave = (value: Value) => {
        setEdit(false)
        handleUpdate(value)
    }

    let scheduleAt = placeholder
    let className: string | undefined = "text-secondary"
    if (defaultValue) {
        className = undefined
        scheduleAt = moment(`${defaultValue}`).format('LLL')
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{scheduleAt}</Col>
    </Row>
    if (edit) {
        view = <Row>
            <Col xs={'auto'}><Icon.Calendar /></Col>
            <Col>
                <DateTimePicker
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    format={'y-MM-dd HH:mm'}
                />
                <ButtonGroup>
                    <Button
                        disabled={disabled}
                        size="sm"
                        variant="link"
                        onClick={() => handleSave(value)}
                    >
                        Save
                    </Button>
                    <Button
                        disabled={disabled}
                        size="sm"
                        variant="link"
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    }

    return view
}

export default EditDateTime