import { baseSplitApi } from "../../Services/BaseApi"
import { INotification } from "../../Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listNotifications: build.query<INotification[], void>({
            query: () => '/notifications',
            providesTags: ["Notifications"],
        }),
        notificationsMarkRead: build.mutation<void, void>({
            query: () => ({
                url: '/notifications-mark-read',
                method: 'GET',
            }),
            invalidatesTags: ["Notifications"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListNotificationsQuery,
    useNotificationsMarkReadMutation,
} = api