import Avatar from "react-avatar"

function UserAvatar({ user, className }: {
    user: {
        name: string
        picture?: string
    }
    className?: string
}) {

    let avatar = <Avatar
        size={'20'}
        name={user.name}
    />
    if (user.picture) {
        avatar = <Avatar
            size={'20'}
            src={user.picture}
            round={true}
        />
    }

    return (
        <span className={className}>{avatar}</span>
    )
}

export default UserAvatar