import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery, useListShoppingListsQuery } from "../../Store/Event/Event.service"
import * as Icon from 'react-bootstrap-icons'
import EventUserAvatars from "../EventUserAvatars"
import ShoppingListItem from "../ShoppingListItem"
import { useMemo } from "react"
import { Member } from "../../Types"

function Event() {

    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ?? skipToken
    const eventQuery = useGetEventQuery(args)

    const listsQuery = useListShoppingListsQuery(args)

    const members = useMemo(() => {
        if (eventQuery.isLoading || !eventQuery.isSuccess || !eventQuery.data?.members?.length) {
            return []
        }
        const list = eventQuery.data
        const members = list.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [eventQuery.isLoading, eventQuery.isSuccess, eventQuery.data])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>Events</Breadcrumb.Item>
                        <Breadcrumb.Item active>{eventQuery.data?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col xs={10}>
                    <h1>{eventQuery.data?.title}</h1>
                </Col>
                <Col className="text-end">
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => navigate(`/app/events-edit/${eventId}?r=/app/events/${eventId}`)}
                    >
                        <Icon.Pencil />
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {members.length ? <EventUserAvatars users={members.map(member => member)} /> : null}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>Shopping Lists</h3>
                </Col>
                <Col className="text-end">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate(`/app/events/${eventId}/lists-add`)}
                    >
                        <Icon.Plus />
                    </Button>
                </Col>
            </Row>
            {eventId ? <Row>
                <Col>
                    {listsQuery.data?.map(list => <ShoppingListItem
                        key={list._id}
                        eventId={eventId}
                        list={list}
                        isShared={false}
                        canEdit={true}
                    />)}
                </Col>
            </Row> : null}
        </Container>
    )
}

export default Event