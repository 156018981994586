import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { INotificationsState } from './Types'
import { api } from './Notifications.service'

const initialState: INotificationsState = {
    show: false,
    notifications: [],
}

export const notificationsSlice = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {
        showNotifications(state, action: PayloadAction<boolean>) {
            state.show = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.listNotifications.matchPending,
                (state) => {
                    state.notifications = []
                }
            )
            .addMatcher(
                api.endpoints.listNotifications.matchFulfilled,
                (state, { payload }) => {
                    state.notifications = payload
                }
            )
            .addMatcher(
                api.endpoints.listNotifications.matchRejected,
                (state) => {
                    state.notifications = []
                }
            )
    }
})

export const {
    showNotifications,
} = notificationsSlice.actions
export default notificationsSlice.reducer