import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Route, Routes, useLocation, useNavigate, } from 'react-router-dom';
// import logo from '../../Images/logo.png'
import Events from '../Events';
import Main from '../Main';
import SList from '../SList';
import Event from '../Events/event';
import { useAuth0 } from '@auth0/auth0-react';
import Account from '../Account';
import { useEffect, useMemo, useState } from 'react';
import AppNotification from '../AppNotification';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/Store';
import { setMessage } from '../../Store/Toast/Toast.slice';
import LocalStorageService, { LocalStorageKeys } from '../../Services/LocalStorage';
import AddEvent from '../Events/add';
import AddShoppingList from '../SList/add';
import AddListProduct from '../ListProduct/add';
import { useGetUserConfigQuery, useSetUserConfigMutation } from '../../Store/UserConfig/UserConfig.service';
import { countryCodes, countryCodesType, currencyCodes, currencyCodesType } from '../../Types';
import { CurrencyService } from '../../Services/Currency';
import { Badge, Col, Row } from 'react-bootstrap';
import { setCountryCode, setCurrencyCode } from '../../Store/UserConfig/UserConfig.slice';
import EditShoppingList from '../SList/edit';
import EditEvent from '../Events/edit';
import SharedEvents from '../SharedEvents';
import SharedEvent from '../SharedEvents/event';
import SharedSList from '../SharedSList';
import AddSharedListProduct from '../ListProduct/shared-add';
import { ApiError } from '../../Services/BaseApi';
import WebPush from '../WebPush';
import ListInvitation from '../ListInvitation';
import EventInvitation from '../EventInvitation';
import Products from '../Products';
import Stores from '../Stores';
import Notifications from '../Notifications';
import * as Icon from 'react-bootstrap-icons'
import { useListNotificationsQuery } from '../../Store/Notifications/Notifications.service';
import { showNotifications } from '../../Store/Notifications/Notifications.slice';

function Navigation() {

    const { user, isAuthenticated, isLoading, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation()
    const navigate = useNavigate()

    const countryCode = useSelector((state: RootState) => state.userConfig.countryCode)
    const currencyCode = useSelector((state: RootState) => state.userConfig.currencyCode)
    const showNotificationsFlag = useSelector((state: RootState) => state.notifications.show)

    const [currencySymbol, setCurrencySymbol] = useState('$')
    const [countryName, setCountryName] = useState('United States')

    const getUserConfigQuery = useGetUserConfigQuery()
    const [setUserConfig] = useSetUserConfigMutation()
    const notificationsQuery = useListNotificationsQuery()

    const totalUnreadNotifications = useMemo(() => {
        let total = 0
        if (!notificationsQuery.isLoading && notificationsQuery.isSuccess && notificationsQuery.data) {
            notificationsQuery.data.forEach(n => {
                total += n.isRead ? 0 : 1
            })
        }
        return total
    }, [notificationsQuery.isLoading, notificationsQuery.isSuccess, notificationsQuery.data])

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: location.pathname,

                },
            })
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        const getUserMetadata = async () => {
            if (isAuthenticated) {
                try {
                    const accessToken = await getAccessTokenSilently();
                    await LocalStorageService.setItem(LocalStorageKeys.AUTH_TOKEN, accessToken)
                    await LocalStorageService.setItem(LocalStorageKeys.USER_ID, user?.sub)
                } catch (e) {
                    dispatch(setMessage((e as Error).message))
                }
            }
        };

        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub, isAuthenticated, dispatch]);

    useEffect(() => {
        if (!getUserConfigQuery.isLoading && getUserConfigQuery.isSuccess && getUserConfigQuery.data) {
            dispatch(setCurrencyCode(getUserConfigQuery.data.currencyCode))
            dispatch(setCountryCode(getUserConfigQuery.data.countryCode))
        }
    }, [getUserConfigQuery.data, getUserConfigQuery.isLoading, getUserConfigQuery.isSuccess, dispatch])

    useEffect(() => {
        const currency = CurrencyService.getCurrency(currencyCode)
        let symbol = '$'
        if (currency) {
            symbol = currency.symbol
        }
        setCurrencySymbol(symbol)
    }, [currencyCode])

    useEffect(() => {
        const countryName = CurrencyService.getCountryNameByCode(countryCode)
        let name = 'United States'
        if (countryName) {
            name = countryName
        }
        setCountryName(name)
    }, [countryCode])

    const signOut = () => {
        //dispatch(logoutAsync())
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const onNav = (path: string) => {
        navigate(path)
    }

    const changeCurrency = async (code: currencyCodesType) => {
        try {
            await setUserConfig({
                currencyCode: code,
            }).unwrap()
            dispatch(setMessage('Configuration saved'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const changeCountry = async (code: countryCodesType) => {
        try {
            await setUserConfig({
                countryCode: code,
            }).unwrap()
            dispatch(setMessage('Configuration saved'))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="mb-3">
                <Container>
                    <Navbar.Brand onClick={() => onNav('/app')}>
                        {/* <img
                            alt="Shopping List"
                            src={logo}
                            height="30"
                            className="d-inline-block align-top"
                        />{' '} */}
                        Shopping List
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav activeKey={location.pathname} className="me-auto">
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/app')}>Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/app/events')}>Events</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/app/shared-events')}>Shared Events</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/app/products')}>Products</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/app/stores')}>Stores</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <NavDropdown title={currencySymbol} id="basic-nav-dropdown">
                                {currencyCodes.map(code => {
                                    const c = CurrencyService.getCurrency(code)
                                    if (!c) {
                                        return null
                                    }
                                    return (
                                        <NavDropdown.Item
                                            key={code}
                                            active={code === currencyCode}
                                            onClick={() => changeCurrency(code)}
                                        >
                                            <Row>
                                                <Col>{c?.name}</Col>
                                                <Col className='text-end'>{c?.symbol}</Col>
                                            </Row>
                                        </NavDropdown.Item>
                                    )
                                })}
                            </NavDropdown>
                            <NavDropdown title={countryName} id="basic-nav-dropdown">
                                {countryCodes.map(code => {
                                    const countryName = CurrencyService.getCountryNameByCode(code)
                                    return (
                                        <NavDropdown.Item
                                            key={code}
                                            active={code === countryCode}
                                            onClick={() => changeCountry(code)}
                                        >
                                            <span>{countryName?.substring(0, 30)}{undefined !== countryName?.length && countryName.length > 30 ? '...' : ''}</span>
                                        </NavDropdown.Item>
                                    )
                                })}
                            </NavDropdown>
                        </Nav>
                        <Nav activeKey={location.pathname}>
                            <Nav.Item>
                                <Nav.Link onClick={() => dispatch(showNotifications(!showNotificationsFlag))}>
                                    {totalUnreadNotifications > 0 ? <Badge>{totalUnreadNotifications}</Badge> : null}
                                    {totalUnreadNotifications > 0 ? <Icon.BellFill /> : <Icon.Bell />}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {!isLoading && isAuthenticated ? <Nav>
                            <NavDropdown title={user?.name} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => onNav('/app/account')}>
                                    Account
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    Terms of Use
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    Privacy Policy
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => signOut()}>
                                    Sign out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav> : <Nav>
                            <Nav.Item>
                                <Nav.Link onClick={() => loginWithRedirect()}>Sign In</Nav.Link>
                            </Nav.Item>
                        </Nav>}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <AppNotification />
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/account' element={<Account />} />
                <Route path='/events' element={<Events />} />
                <Route path='/events-add' element={<AddEvent />} />
                <Route path='/events/:eventId' element={<Event />} />
                <Route path='/events-edit/:eventId' element={<EditEvent />} />
                <Route path='/events/:eventId/lists-add' element={<AddShoppingList />} />
                <Route path='/events/:eventId/lists/:listId' element={<SList />} />
                <Route path='/events/:eventId/lists-edit/:listId' element={<EditShoppingList />} />
                <Route path='/events/:eventId/lists/:listId/products-add' element={<AddListProduct />} />
                <Route path='/shared-events' element={<SharedEvents />} />
                <Route path='/shared-events/:eventId' element={<SharedEvent />} />
                <Route path='/shared-events/:eventId/shared-lists/:listId' element={<SharedSList />} />
                <Route path='/shared-events/:eventId/shared-lists/:listId/shared-products-add' element={<AddSharedListProduct />} />
                <Route path='/event-invitations/:token' element={<EventInvitation />} />
                <Route path='/list-invitations/:token' element={<ListInvitation />} />
                <Route path='/products' element={<Products />} />
                <Route path='/stores' element={<Stores />} />
            </Routes>
            {isAuthenticated ? <WebPush /> : null}
            <Notifications />
        </>
    );
}

export default Navigation;
