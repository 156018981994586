import { useNavigate } from "react-router-dom"
import { ListGroup } from "react-bootstrap"
import EventUserAvatars from "../EventUserAvatars"
import * as Icon from 'react-bootstrap-icons'
import { IEvent, Member } from "../../Types"
import { useMemo } from "react"

function EventListItem({ event, canEdit, isShared }: { event: IEvent, canEdit: boolean, isShared: boolean }) {

    const navigate = useNavigate()

    const members = useMemo(() => {
        let members: Member[] = []
        if (event.members?.length) {
            members = event.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [event.members])

    return (
        <ListGroup.Item
            className="d-flex justify-content-between align-items-start"
        >
            <div
                onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/` + event._id)}
                className="ms-2 me-auto">
                {event.title}
            </div>
            {members.length ? <EventUserAvatars users={members} /> : null}
            {canEdit ? <Icon.ThreeDotsVertical
                onClick={() => {
                    navigate(`/app/events-edit/${event._id}?r=/app/events`)
                }}
            /> : null}
        </ListGroup.Item>
    )
}

export default EventListItem