import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StoreState } from './Types'

const initialState: StoreState = {
    showStoreFinder: false,
}

export const storeSlice = createSlice({
    name: 'Store',
    initialState,
    reducers: {
        showStoreFinder(state, action: PayloadAction<boolean>) {
            state.showStoreFinder = action.payload
        },
    },
})

export const {
    showStoreFinder,
} = storeSlice.actions
export default storeSlice.reducer