import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import AddShoppingListForm, { AddShoppingListFormData } from "./add-form"
import { useAddShoppingListMutation, useGetEventQuery } from "../../Store/Event/Event.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { skipToken } from "@reduxjs/toolkit/query"
import { ApiError } from "../../Services/BaseApi"

function AddShoppingList() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ?? skipToken
    const eventQuery = useGetEventQuery(args)

    const [addShoppingList, addShoppingListResult] = useAddShoppingListMutation()

    const handleSubmit = async (data: AddShoppingListFormData) => {
        try {
            if (eventId) {
                const list = await addShoppingList({ eventId, ...data }).unwrap()
                navigate('/app/events/' + eventId + '/lists-edit/' + list._id + '?r=/app/events/' + eventId + '/lists/' + list._id)
            } else {
                dispatch(setMessage('Event not found'))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>Events</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId)}>{eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Add New Shopping List</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {eventQuery.data && eventId ? <Row>
                <Col>
                    <AddShoppingListForm
                        disabled={addShoppingListResult.isLoading}
                        handleSubmit={handleSubmit}
                        eventId={eventId}
                    />
                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="secondary"
                            onClick={() => navigate(`/app/events/${eventId}`)}
                        >
                            Cancel
                        </Button>
                    </div>

                </Col>
            </Row> : null}
        </Container>
    )
}

export default AddShoppingList