import Toast from 'react-bootstrap/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/Store';
import { hideMessage } from '../../Store/Toast/Toast.slice';
import { ToastContainer } from 'react-bootstrap';

const TIMEOUT = 5000 // hide message after 5 sec

function AppNotification() {

    const dispatch = useDispatch<AppDispatch>()
    const message = useSelector((state: RootState) => state.toast.message)
    const show = useSelector((state: RootState) => state.toast.show)

    return (
        <ToastContainer
            className="p-3"
            position='bottom-center'
            style={{ zIndex: 1 }}
        >
            <Toast
                show={show}
                onClose={() => dispatch(hideMessage())}
                delay={TIMEOUT}
                autohide>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default AppNotification