import { useEffect, useState } from "react"
import { Form, ListGroup, Offcanvas } from "react-bootstrap"
import { useListStoresQuery } from "../../Store/Event/Event.service"
import { skipToken } from "@reduxjs/toolkit/query"
import { IStore } from "../../Types"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { showStoreFinder } from "../../Store/Store/Store.slice"
import React from "react"
import { useForm } from "react-hook-form"

function StoreFinder({ onStoreSelect }: { onStoreSelect: (store: IStore) => void }) {

    const dispatch = useDispatch<AppDispatch>()
    const [storeName, setStoreName] = useState('')
    const [storeNameTmp, setStoreNameTmp] = useState('')
    const [stores, setStores] = useState<IStore[]>([])
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    const show = useSelector((state: RootState) => state.store.showStoreFinder)

    const args = storeName?.length > 1 ? storeName.toLowerCase() : skipToken
    const storeQuery = useListStoresQuery(args)

    const initState = {
        name: '',
    }

    const [initialValues] = React.useState(initState)

    const {
        register,
        setFocus,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setFocus('name')
            }, 1000)
        }
    }, [show, setFocus])

    useEffect(() => {
        if (!storeQuery.isLoading) {
            if (!storeQuery.data) {
                setStores([])
            } else {
                setStores([...storeQuery.data])
            }
        }
    }, [storeQuery.data, storeQuery.isLoading])

    useEffect(() => {
        if (!storeName || storeName.length < 2) {
            setStores([])
        }
    }, [storeName])

    useEffect(() => {
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        const t = setTimeout(() => {
            setStoreName(storeNameTmp)
        }, 2000)
        setTimer(t)
    }, [storeNameTmp])

    const onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
        setStoreNameTmp(e.target.value)
    }

    const handleClose = () => {
        setStoreNameTmp('')
        setStoreName('')
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        dispatch(showStoreFinder(false))
    }

    const onSelect = (store: IStore) => {
        setStoreNameTmp('')
        setStoreName('')
        onStoreSelect(store)
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Find Store</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                            type="text"
                            placeholder="Type store name"
                            {...register("name")}
                            onChange={onChange}
                        />
                    </Form.Group>
                </Form>

                <ListGroup>
                    {storeName ? <ListGroup.Item
                        onClick={() => onSelect({
                            _id: "",
                            userId: "",
                            name: storeName,
                        })}
                    >{storeName}</ListGroup.Item> : null}
                    <ListGroup.Item
                        onClick={() => onSelect({
                            _id: "",
                            userId: "",
                            name: "",
                        })}
                    >No Store</ListGroup.Item>
                    {stores.map(store => <ListGroup.Item
                        key={store._id}
                        onClick={() => onSelect(store)}
                    >
                        <div>{store.name}</div>
                        <div className="small">{store.description}</div>
                    </ListGroup.Item>)}
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default StoreFinder