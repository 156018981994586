import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import AddListProductForm, { AddListProductFormData } from "./add-form"
import { useAddListProductMutation, useGetEventQuery, useGetShoppingListQuery } from "../../Store/Event/Event.service"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { skipToken } from "@reduxjs/toolkit/query"
import { ApiError } from "../../Services/BaseApi"

function AddListProduct() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { eventId, listId } = useParams()

    const currencyCode = useSelector((state: RootState) => state.userConfig.currencyCode)

    const eventArgs = eventId ?? skipToken
    const eventQuery = useGetEventQuery(eventArgs)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetShoppingListQuery(listArgs)

    const [addProduct, addProductResult] = useAddListProductMutation()

    const handleSubmit = async (data: AddListProductFormData) => {
        try {
            if (eventId && listId) {
                await addProduct({ eventId, listId, ...data }).unwrap()
                navigate('/app/events/' + eventId + '/lists/' + listId)
            } else {
                dispatch(setMessage('Event not found'))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>Events</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId)}>{eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId + '/lists/' + listQuery.data?._id)}>{listQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Add New Product</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {eventQuery.data && listQuery.data ? <Row>
                <Col>
                    <AddListProductForm
                        disabled={addProductResult.isLoading}
                        handleSubmit={handleSubmit}
                        currencyCode={currencyCode}
                    />
                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="secondary"
                            onClick={() => navigate('/app/events/' + eventId + '/lists/' + listId)}
                        >
                            Cancel
                        </Button>
                    </div>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default AddListProduct