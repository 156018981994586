import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap"
import { useDeleteStoresMutation, useListStoresQuery } from "../../Store/Event/Event.service"
import { useEffect, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import EditStore from "./Edit"
import { IStore } from "../../Types"

function Stores() {

    const dispatch = useDispatch<AppDispatch>()
    const storesQuery = useListStoresQuery('')

    const [selected, setSelected] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState(false)
    const [editStore, setEditStore] = useState<IStore | null>(null)
    const [showEdit, setShowEdit] = useState(false)

    const [deleteStores] = useDeleteStoresMutation()

    useEffect(() => {
        if (!storesQuery.isLoading && storesQuery.isSuccess && storesQuery.data) {
            if (editStore) {
                for (const store of storesQuery.data) {
                    if (store._id === editStore._id) {
                        setEditStore({ ...store })
                        break
                    }
                }
            }
        }
    }, [storesQuery.isLoading, storesQuery.isSuccess, storesQuery.data])

    const toggleSelectAll = () => {
        if (selected.length) {
            setSelected([])
            setAllSelected(false)
        } else {
            let s: string[] = []
            if (storesQuery.data) {
                s = storesQuery.data?.map(p => p._id)
            }
            setSelected(s)
            setAllSelected(true)
        }
    }

    const deleteSelectedProducts = async () => {
        if (selected.length) {
            const confirm = window.confirm(`Delete ${selected.length} stores?`)
            if (confirm) {
                try {
                    await deleteStores(selected)
                    setSelected([])
                    setAllSelected(false)
                } catch (err) {
                    dispatch(setMessage((err as ApiError).data.error))
                }
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col><h3>My Stores</h3></Col>
            </Row>
            {selected.length ? <Row className="mb-3">
                <Col>
                    <div className="d-grid gap-2">
                        <ButtonGroup>
                            <Button variant="danger" onClick={deleteSelectedProducts}><Icon.Trash /></Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row> : null}
            {storesQuery.data?.length ? <Row className="mb-3">
                <Col><label><input type="checkbox" onChange={toggleSelectAll} checked={allSelected} /> Select all</label></Col>
            </Row> : null}
            {storesQuery.data?.length ? <Row>
                <Col>
                    {editStore ? <EditStore
                        show={showEdit}
                        handleClose={() => {
                            setEditStore(null)
                            setShowEdit(false)
                        }}
                        store={editStore}
                    /> : null}
                    {storesQuery.data?.map(store => {

                        return (
                            <Row key={store._id}>
                                <Col xs="auto">
                                    <input type="checkbox" checked={selected.includes(store._id)} onChange={() => {
                                        if (selected.includes(store._id)) {
                                            setSelected(selected.filter(p => p !== store._id))
                                            setAllSelected(false)
                                        } else {
                                            setSelected([...selected, store._id])
                                        }
                                    }} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="fw-bold">{store.name}</Col>
                                        <Col xs="auto"><Icon.Pencil onClick={() => {
                                            setEditStore(store)
                                            setShowEdit(true)
                                        }} /></Col>
                                    </Row>
                                    <Row>
                                        <Col>{store.description}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{store.address}</Col>
                                    </Row>
                                    <Row>
                                        <Col><hr /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row> : <Row>
                <Col>
                    No stores
                </Col>
            </Row>}
        </Container>
    )
}

export default Stores