import { Card, Col, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { IMember } from "../../../Types"
import UserAvatar from "../../UserAvatar"
import { DateService } from "../../../Services/Date"

function EntityMembers({ members, unshare }: { members?: IMember[], unshare: (email: string) => void }) {

    return (
        <>
            {members?.length ? <h3>Members</h3> : null}
            {!members?.length ? <div className="mb-3 text-center">No members</div> : null}
            {members?.map(member => {
                let isSelected = false
                if (member.userId) {
                    isSelected = true
                }
                return (
                    <Card
                        border={isSelected ? "success" : undefined}
                        key={member.email}
                        className="mb-3"
                    >
                        <Card.Body>
                            <Row>
                                {member?.name ? <Col xs="auto"><UserAvatar user={{
                                    name: member.name,
                                    picture: member.picture,
                                }} /></Col> : null}
                                <Col>
                                    <Row>
                                        {member?.userId ? <Col>{member.name}</Col> : null}
                                        {!member?.userId ? <Col>{member.email}</Col> : null}
                                    </Row>
                                    <Row>
                                        {member?.userId ? <Col className="small">{member.email}</Col> : null}
                                        {!member?.userId ? <Col className="small">{DateService.formatDate(member.invitedAt)}</Col> : null}
                                    </Row>
                                </Col>
                                <Col xs="auto">
                                    {member?.userId ? <Icon.Check className="text-success" /> : null}
                                    {!member?.userId ? <Icon.Clock className="text-secondary" /> : null}
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Icon.Trash onClick={() => unshare(member.email)} />
                        </Card.Footer>
                    </Card>
                )
            })}
        </>
    )
}

export default EntityMembers